import { GlobalService } from './shared/services/global.service';
import { Component, OnInit } from '@angular/core';
import { LoggerService } from './shared/services/logger.service';
import { AuthService } from './shared/services/auth/auth.service';
import { FsPageLoaderService } from './shared/modules/fs-controls/fs-page-loader/fs-page-loader.service';
import { Router } from '@angular/router';
import { ConfirmAccountState } from './shared/enums/auth.enum';
import { AWSResponseType } from './shared/services/error-auth';
import { FsLoaderService } from './shared/modules/fs-controls/fs-loader/fs-loader.service';
import { UrlService } from './shared/services/url/url.service';
import {
  ConfirmUserModel,
  SessionModel,
  UrlParameterFor,
} from './shared/services/url/url.model';
import { AlertService } from './pages/alert/alert.service';
import { FsToastService } from './shared/modules/fs-popups/fs-toast/fs-toast.service';

//amirhoseinghorbani796+89@gmail.com
//QU4H-DA4S

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  applicationReady: boolean;

  constructor(
    private loggerService: LoggerService,
    public authService: AuthService,
    public pageLoader: FsPageLoaderService,
    private loaderService: FsLoaderService,
    private urlService: UrlService,
    public router: Router,
    public globalService: GlobalService,
    private alertService: AlertService,
    private toastService: FsToastService
  ) {
    this.applicationReady = false;

    this.loggerService.printVersion();
    this.globalService.checkConfirmationState();
    this.urlService.checkQueryParameters();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.pageLoader.loader = true;

      const result = await this.authService.isTokenValid();
      if (!result) await this.clearAuth();
      else {
        const player = await this.authService.getCompetePlayer();
        if (!player) {
          await this.clearAuth();
          this.toastService.show('ERROR', 'Account not found');
          return;
        } else {
          this.globalService.player = player;
          this.globalService.currentConfirmAccount =
            ConfirmAccountState.Confirmed;
          this.authService.isAuthenticated = true;
        }
      }

      await this.checkSessionId();
      await this.checkConfirmationAccount();
      this.checkResetPassword();
      this.checkRedirected();
    } catch (error) {
      console.error(error);
    } finally {
      this.pageLoader.loader = false;
      this.applicationReady = true;
    }
  }

  async checkSessionId(): Promise<void> {
    if (this.urlService.urlState === UrlParameterFor.SessionId) {
      this.globalService.fromSessionId = true;

      if (this.authService.isAuthenticated) {
        const joinResult = await this.authService.joinSession(
          (this.urlService.paramData as SessionModel).sessionId
        );

        switch (joinResult?.status?.toLowerCase()) {
          case 'already joined':
            this.alertService.showAlreadyJoined(() => this.signOut());
            break;
          case 'success':
            this.router.navigateByUrl('/sign-in');
            break;
          default:
            await this.toastService.show('ERROR', joinResult.status);
            this.router.navigateByUrl('/');
            break;
        }
      } else {
        window.localStorage.setItem(
          'SessionId',
          this.urlService.paramData.sessionId
        );

        this.router.navigateByUrl('/');
      }
    }
  }

  async checkConfirmationAccount(): Promise<void> {
    if (this.urlService.urlState === UrlParameterFor.ConfirmAccount) {
      const isConfirmed = await this.authService.confirmSignUp(
        new ConfirmUserModel({
          ...this.urlService.paramData,
        })
      );

      if (isConfirmed.type === AWSResponseType.Success) {
        if (this.authService.isAuthenticated) {
          await this.clearAuth();
        }

        this.globalService.currentConfirmAccount =
          ConfirmAccountState.Confirmed;

        this.alertService.showAccountVerified(() =>
          this.router.navigateByUrl('/sign-in')
        );
      }
    }
  }

  checkResetPassword(): void {
    if (this.urlService.urlState === UrlParameterFor.ResetPassword) {
      this.router.navigateByUrl('/reset-password');
    }
  }

  async checkRedirected(): Promise<void> {
    // if (this.urlService.urlState === UrlParameterFor.Redirected) {
    //   window.localStorage.setItem(
    //     'redirect_url',
    //     this.urlService.paramData.redirectUrl
    //   );

    //   if (this.urlService.paramData.action === 'sign_in') {
    //     this.router.navigateByUrl('/sign-in');
    //   } else if (this.urlService.paramData.action === 'sign_up') {
    //     this.router.navigateByUrl('/create-account');
    //   } else if (this.urlService.paramData.action === 'sign_out') {
    //     window.localStorage.removeItem('redirect_url');
    //     await this.signOut(this.urlService.paramData.redirectUrl);
    //   } else {
    //     this.router.navigateByUrl('/');
    //   }
    // }

    if (this.urlService.paramData?.redirectUrl?.length) {
      window.localStorage.setItem(
        'redirect_url',
        this.urlService.paramData.redirectUrl
      );

      if (this.urlService.urlState === UrlParameterFor.Redirected) {
        if (this.urlService.paramData.action === 'sign_in') {
          this.router.navigateByUrl('/sign-in');
        } else if (this.urlService.paramData.action === 'sign_up') {
          this.router.navigateByUrl('/create-account');
        } else if (this.urlService.paramData.action === 'sign_out') {
          window.localStorage.removeItem('redirect_url');
          await this.signOut(this.urlService.paramData.redirectUrl);
        } else {
          this.router.navigateByUrl('/');
        }
      }
    }
  }

  async clearAuth(): Promise<void> {
    await this.authService.unautorized([
      'AccountEmail',
      'ConfirmAccount',
      '_fs_cp_page_his',
      'SignUpResponse',
      'SessionId',
      'CompletedAccount',
      'redirect_url',
    ]);
  }

  async signOut(redirectUrl = '/'): Promise<void> {
    this.loaderService.loader = true;
    await this.authService.unautorized(['CompletedAccount']);
    window.location.href = redirectUrl;
    this.loaderService.loader = false;
  }

  // async onContinue(): Promise<void> {
  //   this.loaderService.loader = true;

  //   const idToken = await this.authService.getIdToken(),
  //     userId = await this.authService.getUserId();

  //   if (idToken && userId?.length) {
  //     window.location.href = `https://fs-compete-by-hnl.netlify.app/?sub_id=${userId}&id_token=${idToken.getJwtToken()}`;
  //   } else {
  //     this.toastService.show('ERROR', 'Redirection Error');
  //     await this.signOut();
  //   }

  //   this.loaderService.loader = false;
  // }
}
