<div class="page-flow">
    <div class="container">
        <form autocomplete="off" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <fs-grid [options]="gridOptions">
                <fs-grid-cell>

                    <div class="profile-header">
                        <div class="profile-image-viewer">
                            <span>GD</span>
                        </div>
                        <!-- <a href="#">Change profile photo</a> -->
                    </div>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field [readOnly]="true" [hideActions]="true">
                        <label for="email">Email</label>
                        <input fs-input id="email" type="email" [maxlength]="EmailMaxLength"
                            [formControl]="profileFormControls.email">
                    </fs-form-field>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field
                        [invalid]="profileFormControls.firstName.touched && profileFormControls.firstName.invalid"
                        (onClearValue)="profileFormControls.firstName.setValue('')">
                        <label for="firstName">First Name</label>
                        <input fs-input id="firstName" type="text" [maxLength]="FirstNameMaxLength"
                            [formControl]="profileFormControls.firstName">
                    </fs-form-field>
                    <small *ngIf="profileFormControls.firstName.touched && profileFormControls.firstName.invalid"
                        fs-small-message [type]="'Error'">Please enter a valid value</small>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field
                        [invalid]="profileFormControls.lastName.touched && profileFormControls.lastName.invalid"
                        (onClearValue)="profileFormControls.lastName.setValue('')">
                        <label for="lastName">Last Name</label>
                        <input fs-input id="lastName" type="text" [maxLength]="LastNameMaxLength"
                            [formControl]="profileFormControls.lastName">
                    </fs-form-field>
                    <small *ngIf="profileFormControls.lastName.touched && profileFormControls.lastName.invalid"
                        fs-small-message [type]="'Error'">Please enter a valid value</small>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field>
                        <label>Handedness</label>
                        <fs-list-selector [placeholder]="'None'" [options]="handednessSelector"
                            [value]="profileFormControls.handedness.value"
                            (valueChange)="profileFormControls.handedness.setValue($event)"></fs-list-selector>
                    </fs-form-field>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field>
                        <label>Gender</label>
                        <fs-list-selector [placeholder]="'None'" [options]="genderSelectors"
                            [value]="profileFormControls.gender.value"
                            (valueChange)="profileFormControls.gender.setValue($event)"></fs-list-selector>
                    </fs-form-field>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field
                        [invalid]="profileFormControls.handicap.touched && profileFormControls.handicap.invalid"
                        [hideActions]="true" [type]="'number'">
                        <label for="handicap">Handicap</label>
                        <input fs-input id="handicap" type="number" [min]="handicapLimits.min"
                            [max]="handicapLimits.max" maxlength="2" [formControl]="profileFormControls.handicap">
                    </fs-form-field>
                    <small *ngIf="profileFormControls.handicap.touched && profileFormControls.handicap.invalid"
                        fs-small-message [type]="'Error'">Please enter a valid value</small>

                </fs-grid-cell>
                <fs-grid-cell>

                    <button fs-button [color]="'primary'" type="submit"
                        [disabled]="loaderService.loader || !profileForm.valid">Save</button>

                </fs-grid-cell>
                <fs-grid-cell>

                    <a class="text-center" (click)="nextPage('/')">Continue</a>

                </fs-grid-cell>
            </fs-grid>
        </form>
    </div>
</div>