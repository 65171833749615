<div *ngIf="showPage" class="page-flow">
    <div class="container">
        <form autocomplete="off" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
            <fs-grid [options]="gridOptions">
                <fs-grid-cell>

                    <fs-form-field [invalid]="signInFormControl.email.touched && signInFormControl.email.invalid"
                        (onClearValue)="signInFormControl.email.setValue('')">
                        <label for="email">Email</label>
                        <input fs-input id="email" type="email" [maxlength]="EmailMaxLength"
                            [formControl]="signInFormControl.email">
                    </fs-form-field>
                    <small *ngIf="signInFormControl.email.touched && signInFormControl.email.invalid" fs-small-message
                        [type]="'Error'">Please enter a valid email address</small>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field [invalid]="signInFormControl.password.touched && signInFormControl.password.invalid"
                        [type]="'password'">
                        <label for="password">Password</label>
                        <input fs-input id="password" type="password" [maxLength]="PasswordMaxLength"
                            [formControl]="signInFormControl.password">
                    </fs-form-field>
                    <small *ngIf="signInFormControl.password.touched && signInFormControl.password.invalid"
                        fs-small-message [type]="'Error'">Please enter a password</small>

                </fs-grid-cell>
                <fs-grid-cell>

                    <a class="text-end mb-40" (click)="nextPage('reset-password')">Forgot your password?</a>

                </fs-grid-cell>
                <fs-grid-cell>

                    <button fs-button [color]="'primary'" type="submit"
                        [disabled]="loaderService.loader || !signInForm.valid">Sign In</button>

                </fs-grid-cell>
            </fs-grid>
        </form>
    </div>
</div>