import { Component, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AWSResponseType } from 'src/app/shared/services/error-auth';
import { UrlService } from 'src/app/shared/services/url/url.service';
import { UrlParameterFor } from 'src/app/shared/services/url/url.model';
import { AlertService } from '../alert/alert.service';
import { FsToastService } from 'src/app/shared/modules/fs-popups/fs-toast/fs-toast.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { CreatePlayerRequest } from 'src/app/shared/models/player.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Router } from '@angular/router';
import {
  EmailMaxLength,
  PasswordMaxLength,
  VerifyCodeMaxLength,
} from 'src/app/shared/static-data';

export enum ResetPasswordPageState {
  InputEmail = 1,
  SentEmail = 2,
  NewPasswords = 3,
  Successfuly = 4,
  NewPasswordRequired = 5,
}

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends FsPageAction implements OnDestroy {
  readonly PasswordMaxLength = PasswordMaxLength;
  readonly EmailMaxLength = EmailMaxLength;
  readonly VerifyCodeMaxLength = VerifyCodeMaxLength;

  isValidPassword: boolean;

  forgotEmailFormControls = {
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(EmailMaxLength),
    ]),
  };
  forgotEmailForm: FormGroup;

  resetPasswordFormControls = {
    code: new FormControl('', [
      Validators.required,
      Validators.maxLength(VerifyCodeMaxLength),
    ]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.maxLength(PasswordMaxLength),
    ]),
    againPassword: new FormControl('', [
      Validators.required,
      Validators.maxLength(PasswordMaxLength),
    ]),
  };
  resetPasswordForm: FormGroup;

  pageState = ResetPasswordPageState;
  currentPageState: ResetPasswordPageState;
  gridOptions1: FsGridOptions;
  gridOptions2: FsGridOptions;

  constructor(
    frameService: FsPageFrameService,
    private formBuilder: FormBuilder,
    public loaderService: FsLoaderService,
    private authService: AuthService,
    private urlService: UrlService,
    private toastService: FsToastService,
    private globalService: GlobalService,
    private alertService: AlertService,
    private helperService: HelperService,
    private router: Router
  ) {
    super(frameService);
    this.createForms();
    this.title = 'Reset Password';
    this.gridOptions1 = new FsGridOptions({
      xsm: [[12], [12], [12], [12]],
      sm: [[12], [12], [12], [12]],
      md: [[12], [12], [12], [12]],
      lg: [[12], [12], [12], [12]],
      xl: [[12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12]],
    });
    this.gridOptions2 = new FsGridOptions({
      xsm: [[12], [12], [12], [12], [12], [12], [12]],
      sm: [[12], [12], [12], [12], [12], [12], [12]],
      md: [[12], [12], [12], [12], [12], [12], [12]],
      lg: [[12], [12], [12], [12], [12], [12], [12]],
      xl: [[12], [12], [12], [12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12], [12], [12], [12]],
    });
    this.setCurrentPageState();
    this.isValidPassword = false;
  }

  ngOnDestroy(): void {
    this.urlService.reset();
  }

  setCurrentPageState(): void {
    if (this.urlService.urlState === UrlParameterFor.ResetPassword) {
      this.currentPageState = ResetPasswordPageState.NewPasswords;

      this.resetPasswordFormControls.code.setValue(
        this.urlService.paramData.code
      );
    } else if (
      this.urlService.urlState === UrlParameterFor.NewPasswordRequired
    ) {
      this.currentPageState = ResetPasswordPageState.NewPasswordRequired;

      this.resetPasswordFormControls.code.setValue(
        this.urlService.paramData.code
      );
    } else {
      this.currentPageState = ResetPasswordPageState.InputEmail;
    }
  }

  createForms(): void {
    this.forgotEmailForm = this.formBuilder.group({
      ...this.forgotEmailFormControls,
    });
    this.resetPasswordForm = this.formBuilder.group({
      ...this.resetPasswordFormControls,
    });
  }

  async onSubmit(): Promise<void> {
    try {
      this.loaderService.loader = true;

      if (this.currentPageState === ResetPasswordPageState.InputEmail) {
        await this.onSubmitForgotEmail();
      } else if (
        this.currentPageState === ResetPasswordPageState.NewPasswords
      ) {
        await this.onSubmitNewPassword();
      } else if (
        this.currentPageState === ResetPasswordPageState.NewPasswordRequired
      ) {
        await this.onSubmitNewPasswordRequired();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  async onSubmitForgotEmail(): Promise<void> {
    this.loaderService.loader = true;

    const result = await this.authService.forgotPassword(
      this.forgotEmailFormControls.email.value
    );

    if (result.type === AWSResponseType.Success) {
      this.currentPageState = ResetPasswordPageState.SentEmail;
    }

    this.loaderService.loader = false;
  }

  async onSubmitNewPassword(): Promise<void> {
    this.loaderService.loader = true;

    const result = await this.authService.forgotPasswordSubmit(
      this.urlService.paramData.username,
      this.resetPasswordFormControls.code.value,
      this.resetPasswordFormControls.newPassword.value
    );

    if (result?.type === AWSResponseType.Success) {
      this.frameService.resetHistory();
      this.alertService.showPasswordChanged(() => this.nextPage('/sign-in'));
      //this.pageLoader.loader = true;

      // this.router.navigateByUrl('/').then((_) => {
      //   window.localStorage.removeItem('_fs_cp_page_his');
      //   window.location.reload();
      // });
    } else {
      this.toastService.show('ERROR', 'Reset password failed');
    }

    this.loaderService.loader = false;
  }

  async onSubmitNewPasswordRequired(): Promise<void> {
    this.loaderService.loader = true;

    const result = await this.authService.completeNewPassword(
      this.urlService.paramData.username,
      this.resetPasswordFormControls.code.value,
      this.resetPasswordFormControls.newPassword.value
    );

    if (typeof result === 'boolean' && result === true) {
      this.frameService.resetHistory();

      const result1 = await this.authService.signIn(
        this.urlService.paramData.username,
        this.resetPasswordFormControls.newPassword.value
      );

      if (result1.error) {
        this.toastService.show('ERROR', 'Reset password failed');
      } else {
        if (result1.type === AWSResponseType.UserIsExists) {
          this.globalService.player = await this.authService.getCompetePlayer();

          if (
            this.globalService?.player === null ||
            this.globalService?.player?.cognitoSub === undefined
          ) {
            const userInfo = await this.authService.getUserInfo();
            const fullName = userInfo.attributes.name.split(' ');

            const playerResult = await this.authService.createCompetePlayer(
              new CreatePlayerRequest({
                email: userInfo.attributes.email,
                firstName: fullName[0] ?? 'unknown',
                lastName: fullName[1] ?? 'unknown',
                cognitoSub: userInfo.username,
              })
            );

            if (!playerResult) {
              this.toastService.show('ERROR', 'Login failed');
              return;
            }

            this.globalService.player = playerResult;
          }

          this.authService.isAuthenticated = true;

          if (!this.globalService.checkAccountIsCompleted()) {
            this.nextPage('/create-account');
          } else {
            const url = await this.checkRedirected();

            if (url?.length) {
              this.alertService.showSignInSuccessWithContinue(
                () => {
                  window.localStorage.removeItem('redirect_url');
                  window.location.href = url;
                },
                () => this.signOut()
              );
            } else {
              this.alertService.showSignInSuccess(() => this.signOut());
            }
          }

          return;
        }
      }
    } else {
      this.toastService.show('ERROR', 'Reset password failed');
    }

    this.loaderService.loader = false;
  }

  async checkRedirected(): Promise<string> {
    const redirectUrl = window.localStorage.getItem('redirect_url');

    if (redirectUrl?.length) {
      return await this.helperService.assignUserTokenToRedirectUrl(redirectUrl);
    }

    return '';
  }

  async signOut(): Promise<void> {
    this.loaderService.loader = true;

    await this.authService.unautorized(['_fs_cp_page_his', 'CompletedAccount']);
    this.router.navigateByUrl('/');

    this.loaderService.loader = false;
  }
}
