import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmAccountState } from 'src/app/shared/enums/auth.enum';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AWSResponseType } from 'src/app/shared/services/error-auth';
import { GlobalService } from 'src/app/shared/services/global.service';
import { AlertService } from '../alert/alert.service';
import { FsToastService } from 'src/app/shared/modules/fs-popups/fs-toast/fs-toast.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { CreatePlayerRequest } from 'src/app/shared/models/player.model';
import { UrlParameterFor } from 'src/app/shared/services/url/url.model';
import { UrlService } from 'src/app/shared/services/url/url.service';
import { EmailMaxLength, PasswordMaxLength } from 'src/app/shared/static-data';

//mohitzadeh+devplayer3@gmail.com
//Aa2551475!

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends FsPageAction implements OnInit {
  readonly PasswordMaxLength = PasswordMaxLength;
  readonly EmailMaxLength = EmailMaxLength;

  signInFormControl = {
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(EmailMaxLength),
      Validators.email,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.maxLength(PasswordMaxLength),
    ]),
  };
  signInForm: FormGroup;
  gridOptions: FsGridOptions;
  showPage?: boolean;

  constructor(
    frameService: FsPageFrameService,
    private formBuilder: FormBuilder,
    public loaderService: FsLoaderService,
    public authService: AuthService,
    private globalService: GlobalService,
    private alertService: AlertService,
    private router: Router,
    private toastService: FsToastService,
    private helperService: HelperService,
    private urlService: UrlService
  ) {
    super(frameService);
    this.createForms();
    this.title = 'Sign In';
    this.gridOptions = new FsGridOptions({
      xsm: [[12], [12], [12], [12]],
      sm: [[12], [12], [12], [12]],
      md: [[12], [12], [12], [12]],
      lg: [[12], [12], [12], [12]],
      xl: [[12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12]],
    });
    this.showPage = true;
  }

  async ngOnInit(): Promise<void> {
    this.loaderService.loader = true;

    if (this.authService.isAuthenticated && this.globalService.player) {
      this.showPage = false;
      const url = await this.checkRedirected();

      if (url?.length) {
        this.alertService.showSignInSuccessWithContinue(
          () => {
            window.localStorage.removeItem('redirect_url');
            window.location.href = url;
          },
          () => this.signOut()
        );
      } else {
        this.alertService.showSignInSuccess(async () => await this.signOut());
      }
    }

    this.showPage = true;
    this.loaderService.loader = false;
  }

  createForms(): void {
    this.signInForm = this.formBuilder.group({ ...this.signInFormControl });
  }

  async onSubmit(): Promise<void> {
    try {
      this.loaderService.loader = true;

      const email = this.signInFormControl.email.value.toLowerCase();
      const password = this.signInFormControl.password.value;

      const result1 = await this.authService.signIn(email, password);

      if (result1.error) {
        if (result1.type === AWSResponseType.UserNotConfirmed) {
          const result2 = await this.authService.reSendSignUpConfirm(email);

          if (result2.type === AWSResponseType.Success) {
            this.globalService.currentConfirmAccount =
              ConfirmAccountState.WaitToConfirm;

            this.alertService.showConfirmEmail(
              this.signInFormControl.email.value,
              async () => {
                this.loaderService.loader = true;

                const result = await this.authService.reSendSignUpConfirm(
                  this.signInFormControl.email.value.toLowerCase()
                );

                if (result.type !== AWSResponseType.Success) {
                  this.toastService.show('ERROR', result.message);
                }

                this.loaderService.loader = false;
              },
              () => this.prevPage('')
            );
          }
        } else {
          this.toastService.show('ERROR', result1.message);
        }
      } else {
        if (result1.type === AWSResponseType.UserIsExists) {
          await this.checkSessionId();

          this.globalService.player = await this.authService.getCompetePlayer();

          if (
            this.globalService?.player === null ||
            this.globalService?.player?.cognitoSub === undefined
          ) {
            const userInfo = await this.authService.getUserInfo();
            const fullName = userInfo.attributes.name.split(' ');

            const playerResult = await this.authService.createCompetePlayer(
              new CreatePlayerRequest({
                email: userInfo.attributes.email,
                firstName: fullName[0] ?? 'unknown',
                lastName: fullName[1] ?? 'unknown',
                cognitoSub: userInfo.username,
              })
            );

            if (!playerResult) {
              this.toastService.show('ERROR', 'Login failed');
              return;
            }

            this.globalService.player = playerResult;
          }

          this.authService.isAuthenticated = true;

          if (!this.globalService.checkAccountIsCompleted()) {
            // this.frameService.resetHistory();
            this.nextPage('/create-account');
          } else {
            const url = await this.checkRedirected();

            if (url?.length) {
              this.alertService.showSignInSuccessWithContinue(
                () => {
                  window.localStorage.removeItem('redirect_url');
                  window.location.href = url;
                },
                () => this.signOut()
              );
            } else {
              this.alertService.showSignInSuccess(() => this.signOut());
            }
          }

          return;

          // if (this.globalService.player) {

          // }
          //  else {
          //   this.toastService.show('ERROR', 'Player not found.');
          // }
        } else if (result1.type === AWSResponseType.NewPasswordRequired) {
          this.urlService.urlState = UrlParameterFor.NewPasswordRequired;
          this.urlService.paramData = {
            code: password,
            username: email,
            type: UrlParameterFor.NewPasswordRequired,
          };
          this.nextPage(`/reset-password`);

          return;
        } else {
          this.toastService.show('ERROR', result1.message);
        }
      }

      this.authService.signOut();
    } catch (error) {
      this.toastService.show('ERROR', error?.message ?? error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  async signOut(): Promise<void> {
    this.loaderService.loader = true;

    await this.authService.unautorized(['_fs_cp_page_his', 'CompletedAccount']);
    this.signInForm.reset();
    this.router.navigateByUrl('/');

    this.loaderService.loader = false;
  }

  async checkSessionId(): Promise<void> {
    const sessionId = window.localStorage.getItem('SessionId');

    if (sessionId?.length) {
      this.globalService.fromSessionId = true;

      const joinResult = await this.authService.joinSession(sessionId);

      if (
        joinResult?.status?.toLowerCase() !== 'success' &&
        joinResult?.status?.toLowerCase() !== 'already joined'
      ) {
        await this.toastService.show('ERROR', joinResult.status);
        this.router.navigateByUrl('/');
      }
    }
  }

  async checkRedirected(): Promise<string> {
    const redirectUrl = window.localStorage.getItem('redirect_url');

    if (redirectUrl?.length) {
      return await this.helperService.assignUserTokenToRedirectUrl(redirectUrl);
    }

    return '';
  }

  goContinue(): void {
    if (this.globalService.player) {
      if (!this.globalService.checkAccountIsCompleted()) {
        this.nextPage('/create-account');
      }
    }
  }
}
