<form *ngIf="authService.isAuthenticated && !globalService.checkAccountIsCompleted(); else signUpTemp"
    autocomplete="off" [formGroup]="accountStep2Form" (ngSubmit)="onAccountToComplete()">
    <div class="page-flow">
        <div class="container">
            <fs-grid [options]="gridOptions2">
                <fs-grid-cell>

                    <p class="text-center mb-16">Please take a moment to set up the basics of your account. You can
                        always update these settings in your account profile.</p>

                </fs-grid-cell>
                <fs-grid-cell>

                    <div class="profile-header">
                        <div class="profile-image-viewer">
                            <span>{{ globalService.imageProfileName }}</span>
                        </div>
                        <!-- <a href="#">Change profile photo</a> -->
                    </div>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field>
                        <label>Handedness</label>
                        <fs-list-selector [placeholder]="'None'" [options]="handednessSelector"
                            [value]="accountStep2FormControls.handedness.value"
                            (valueChange)="accountStep2FormControls.handedness.setValue($event)"></fs-list-selector>
                    </fs-form-field>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field>
                        <label>Gender</label>
                        <fs-list-selector [placeholder]="'None'" [options]="genderSelectors"
                            [value]="accountStep2FormControls.gender.value"
                            (valueChange)="accountStep2FormControls.gender.setValue($event)"></fs-list-selector>
                    </fs-form-field>

                </fs-grid-cell>
                <fs-grid-cell>

                    <fs-form-field
                        [invalid]="accountStep2FormControls.handicap.touched && accountStep2FormControls.handicap.invalid"
                        [hideActions]="true" [type]="'number'">
                        <label for="handicap">Handicap (Optional)</label>
                        <input fs-input id="handicap" type="number" [min]="handicapLimits.min"
                            [max]="handicapLimits.max" maxlength="2" [formControl]="accountStep2FormControls.handicap">
                    </fs-form-field>
                    <small
                        *ngIf="accountStep2FormControls.handicap.touched && accountStep2FormControls.handicap.invalid"
                        fs-small-message [type]="'Error'">Please enter a valid value</small>

                </fs-grid-cell>
                <fs-grid-cell>

                    <button fs-button [color]="'primary'" [disabled]="!accountStep2Form.valid || loaderService.loader"
                        type="submit">Continue</button>

                </fs-grid-cell>
            </fs-grid>
        </div>
    </div>
</form>
<ng-template #signUpTemp>
    <form autocomplete="off" [formGroup]="accountStep1Form" (ngSubmit)="onCreateAccount()">
        <div class="page-flow">
            <div class="container">
                <fs-grid [options]="gridOptions1">
                    <fs-grid-cell>

                        <p class="text-center">Create your Full Swing account and start playing today.</p>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <fs-form-field
                            [invalid]="accountStep1FormControls.email.touched && accountStep1FormControls.email.invalid"
                            (onClearValue)="accountStep1FormControls.email.setValue('')">
                            <label for="email">Email</label>
                            <input fs-input id="email" type="email" [maxlength]="emailMaxLength"
                                [formControl]="accountStep1FormControls.email">
                        </fs-form-field>
                        <small *ngIf="accountStep1FormControls.email.touched && accountStep1FormControls.email.invalid"
                            fs-small-message [type]="'Error'">Please enter a valid email address</small>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <fs-form-field [type]="'password'"
                            [invalid]="accountStep1FormControls.password.touched && accountStep1FormControls.password.invalid">
                            <label for="password">Password</label>
                            <input id="password" type="password" [maxLength]="PasswordMaxLength"
                                [formControl]="accountStep1FormControls.password">
                        </fs-form-field>
                        <small class="mb-16"
                            *ngIf="accountStep1FormControls.password.touched && accountStep1FormControls.password.invalid"
                            fs-small-message [type]="'Error'">Please enter a valid password</small>

                        <fs-password-validity [newPassword]="accountStep1FormControls.password"
                            [confirmPassword]="accountStep1FormControls.confirmPassword"
                            (validationResult)="isValidPassword = $event"></fs-password-validity>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <fs-form-field [type]="'password'"
                            [invalid]="accountStep1FormControls.confirmPassword.touched && accountStep1FormControls.confirmPassword.invalid">
                            <label for="confirm">Confirm New Password</label>
                            <input id="confirm" type="password" [maxLength]="PasswordMaxLength"
                                [formControl]="accountStep1FormControls.confirmPassword">
                        </fs-form-field>
                        <small
                            *ngIf="accountStep1FormControls.confirmPassword.touched && accountStep1FormControls.confirmPassword.invalid"
                            fs-small-message [type]="'Error'">Please enter a valid password</small>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <fs-form-field
                            [invalid]="accountStep1FormControls.firstName.touched && accountStep1FormControls.firstName.invalid"
                            (onClearValue)="accountStep1FormControls.firstName.setValue('')">
                            <label for="firstName">First Name</label>
                            <input fs-input id="firstName" type="text" [maxlength]="firstNameMaxLength"
                                [formControl]="accountStep1FormControls.firstName">
                        </fs-form-field>
                        <small
                            *ngIf="accountStep1FormControls.firstName.touched && accountStep1FormControls.firstName.invalid"
                            fs-small-message [type]="'Error'">Please enter a valid value</small>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <fs-form-field
                            [invalid]="accountStep1FormControls.lastName.touched && accountStep1FormControls.lastName.invalid"
                            (onClearValue)="accountStep1FormControls.lastName.setValue('')">
                            <label for="lastName">Last Name</label>
                            <input fs-input id="lastName" type="text" [maxlength]="lastNameMaxLength"
                                [formControl]="accountStep1FormControls.lastName">
                        </fs-form-field>
                        <small
                            *ngIf="accountStep1FormControls.lastName.touched && accountStep1FormControls.lastName.invalid"
                            fs-small-message [type]="'Error'">Please enter a valid value</small>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <button fs-button [color]="'primary'" type="submit"
                            [disabled]="loaderService.loader || !accountStep1Form.valid || !isValidPassword">Continue</button>

                    </fs-grid-cell>
                    <fs-grid-cell>

                        <p class="text-center">By creating an account you are agreeing to our <a class="d-inline"
                                href="#">Terms
                                of Service</a>, <a class="d-inline" href="#">Privacy Policy</a> and <a class="d-inline"
                                href="#">Subscription Terms</a></p>

                    </fs-grid-cell>
                </fs-grid>
            </div>
        </div>
    </form>
</ng-template>